<script>
import { cookie as cookieManager } from '@emobg/web-utils';

import get from 'lodash/get';
import map from 'lodash/map';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { Validate } from '@emobg/vue-base';

import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';

import { friendlyFormatIBAN, isValidIBAN } from 'ibantools';

import { COMPANY_PAYMENT_METHODS_OPTIONS } from '../const/companyPaymentMethods.const';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';

export default {
  name: 'CompanyPaymentMethodForm',

  components: {
    GenericModalComponent,
    StoreNotificationComponent,
  },

  directives: {
    Validate,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    onSuccess: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const defaultSelectPlaceholder = 'Add Payment Method';
    const inputs = {
      defaultPaymentMethod: 1,
      type: defaultSelectPlaceholder,
      number: '',
      holder: '',
    };

    return {
      defaultSelectPlaceholder,
      inputs,
    };
  },

  data() {
    return {
      isFormValid: false,
      paymentMethodOptions: [],
      personalProfileId: null,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      paymentMethodFormStatus: state => state.paymentMethodForm.STATUS,
    }),

    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isFormValid,
            loading: this.paymentMethodFormStatus.LOADING,
          },
          text: 'Add',
          listeners: {
            click: () => this.addPaymentMethod(),
          },
        },
      ];
    },

    isSepaPaymentMethodSelected() {
      return this.inputs.type === COMPANY_PAYMENT_METHODS_OPTIONS.sepa.value;
    },
  },

  async created() {
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_PAYMENT_METHODS_OPTIONS = COMPANY_PAYMENT_METHODS_OPTIONS;

    this.paymentMethodOptions = [
      {
        label: this.defaultSelectPlaceholder,
        value: this.defaultSelectPlaceholder,
      },
      COMPANY_PAYMENT_METHODS_OPTIONS.bankTransfer,
      COMPANY_PAYMENT_METHODS_OPTIONS.sepa,
    ];

    const flagFromCookie = cookieManager.get('csrevFeatureFlags', true);
    this.csrevFeatureFlags = flagFromCookie ? JSON.parse(flagFromCookie) : null;

    this.isV6Version = !!this.csrevFeatureFlags && !!this.csrevFeatureFlags['CSREV-3684-bo-add-sepa'];
  },

  methods: {
    get,
    map,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'postCompanyPaymentMethod',
      'postAddSepaPaymentMethod',
    ]),

    async addPaymentMethod() {
      const {
        number, holder, type, defaultPaymentMethod,
      } = this.inputs;

      const operableNumber = this.trimBlankSpaces(number);

      if (this.isV6Version && this.isSepaPaymentMethodSelected) {
        const payload = {
          iban: operableNumber,
          owner: holder,
          country: operableNumber.slice(0, 2),
          channel: 'web',
          companyUuid: this.company.uuid,
          origin: document.location.href,
        };

        await this.postAddSepaPaymentMethod({
          payload,
        });
      } else {
        const sepaParameters = this.isSepaPaymentMethodSelected ? {
          holder,
          number,
        } : null;

        const payload = {
          default: defaultPaymentMethod,
          type,
          ...sepaParameters,
        };

        await this.postCompanyPaymentMethod({
          companyId: this.company.id,
          payload,
        });
      }

      if (!this.paymentMethodFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({ message: 'Payment method added' });
      }
    },

    isNotPlaceHolderValidation(value) {
      const message = 'Please select a payment method';
      const isValid = value !== this.defaultSelectPlaceholder;

      return { isValid, message };
    },

    isValidForm(value) {
      this.isFormValid = value.detail.isValid && value.detail.areAllValidated;
    },

    onSelectPaymentType(value) {
      this.inputs.type = value.detail;
      this.inputs.holder = '';
      this.inputs.number = '';

      this.paymentMethodOptions = this.paymentMethodOptions.filter(options => options.label !== this.defaultSelectPlaceholder);
    },

    onChangeHolder(value) {
      this.inputs.holder = value.detail.trim();
    },

    onChangeNumber(value) {
      this.inputs.number = friendlyFormatIBAN(value.detail.trim());
    },

    trimBlankSpaces(value) {
      return value.replace(/\s+/g, '');
    },

    checkIban(iban) {
      const trimmedIBAN = this.trimBlankSpaces(iban);
      const isValid = isValidIBAN(trimmedIBAN);
      const message = 'Please add a valid IBAN';

      return { isValid, message };
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="payment_method-form"
    class="CompanyPaymentMethodForm"
    title="Add payment method"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.paymentMethodForm"
        element="payment method"
      />
    </template>
    <template slot="body">
      <ui-validate
        class="d-flex flex-column"
        @status="isValidForm"
      >
        <p class="emobg-body-1 mb-4">
          The new payment method added to the company will be the active payment method.
        </p>
        <ui-select
          v-validate.select="{
            isRequired: true,
            isNotPlaceHolderValidation,
          }"
          value.prop="inputs.type"
          :options.prop="paymentMethodOptions"
          label="Payment method*"
          class="w-100 order-1"
          name="type"
          @selectoption="onSelectPaymentType"
        />
        <template v-if="inputs.type === get(COMPANY_PAYMENT_METHODS_OPTIONS, 'sepa.value')">
          <ui-text-input
            v-validate.input="{
              isRequired: true,
            }"
            :value="inputs.holder"
            label="Holder*"
            placeholder="Enter the holder name"
            name="holder"
            class="my-4 order-2"
            @changevalue="onChangeHolder"
          />

          <ui-text-input
            v-validate.input="{
              isRequired: true,
              checkIban,
            }"
            :value="inputs.number"
            label="IBAN*"
            placeholder="Enter the IBAN"
            name="iban"
            class="order-3"
            @changevalue="onChangeNumber"
          />
        </template>
      </ui-validate>
    </template>
  </GenericModalComponent>
</template>
